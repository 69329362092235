import {
  ActionReducerMapBuilder,
  createAsyncThunk,
  createSlice,
} from "@reduxjs/toolkit";
import makeAPIRequest from "./makeAPIRequest";
import { NetworkError } from "./authSlice";
import { Lecon } from "../pages/Lecons";

// Thunk pour récupérer toutes les leçons
const getAllLecons = createAsyncThunk(
  "/GET_ALL_LECONS",
  async (payload, thunkAPI): Promise<Lecon[]> => {
    try {
      const response = await makeAPIRequest("get", "/api/v1/lecons/all");
      return thunkAPI.fulfillWithValue(response.data?.lecons) as any;
    } catch (err) {
      return thunkAPI.rejectWithValue(err) as any;
    }
  }
);

// Thunk pour supprimer une leçon
const deleteLecon = createAsyncThunk<
  string,
  string,
  {
    rejectValue: any;
  }
>("/DELETE_LECON", async (payload, thunkAPI): Promise<string> => {
  try {
    await makeAPIRequest("delete", `/api/v1/lecons/${payload}`);
    return thunkAPI.fulfillWithValue(payload) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour récupérer les leçons favorites
const getFavoriteLecons = createAsyncThunk(
  "/GET_FAVORITE_LECONS",
  async (payload, thunkAPI): Promise<Lecon[]> => {
    try {
      const response = await makeAPIRequest("get", "/api/v1/lecons/favorite");
      return thunkAPI.fulfillWithValue(response.data?.lecons) as any;
    } catch (err) {
      return thunkAPI.rejectWithValue(err) as any;
    }
  }
);

// Thunk pour cliquer sur une leçon favorite
const clickFavorite = createAsyncThunk<
  Lecon,
  string,
  {
    rejectValue: any;
  }
>("/CLICK_FAVORITE", async (payload, thunkAPI): Promise<Lecon> => {
  try {
    const response = await makeAPIRequest("get", `/api/v1/lecons/${payload}/favorite`);
    return thunkAPI.fulfillWithValue(response.data?.lecon) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour voir un audio
const viewAudio = createAsyncThunk<
  Lecon,
  string,
  {
    rejectValue: any;
  }
>("/VIEW_AUDIO", async (payload, thunkAPI): Promise<Lecon> => {
  try {
    const response = await makeAPIRequest("get", `/api/v1/audios/${payload}/view`);
    return thunkAPI.fulfillWithValue(response.data?.lecon) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour créer une nouvelle leçon
const createLecon = createAsyncThunk<
  Lecon,
  Partial<Lecon>,
  {
    rejectValue: any;
  }
>("/CREATE_LECON", async (payload, thunkAPI): Promise<Lecon> => {
  try {
    const response = await makeAPIRequest("post", "/api/v1/lecons", payload);
    return thunkAPI.fulfillWithValue(response.data) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Thunk pour modifier une leçon existante
const updateLecon = createAsyncThunk<
  Lecon,
  { id: string; data: Partial<Lecon> },
  {
    rejectValue: any;
  }
>("/UPDATE_LECON", async (payload, thunkAPI): Promise<Lecon> => {
  try {
    const { id, data } = payload;
    const response = await makeAPIRequest("put", `/api/v1/lecons/${id}`, data);
    return thunkAPI.fulfillWithValue(response.data) as any;
  } catch (err) {
    return thunkAPI.rejectWithValue(err) as any;
  }
});

// Interface du state des leçons
export interface LeconsState {
  lecons: Lecon[];
  favorites: Lecon[];
  isLoading: boolean;
  error: NetworkError | undefined;
  success: boolean;
}

// État initial du slice
const initialState: LeconsState = {
  lecons: [],
  favorites: [],
  isLoading: false,
  error: undefined,
  success: false,
};

// Slice pour les leçons
export const leconsSlice = createSlice({
  name: "Lecons",
  initialState,
  reducers: {
    updateLecon: (state, action) => {
      state.lecons = state.lecons.map((x) => (x?._id === action.payload._id ? action.payload : x));
    },
    updateAudio: (state, action) => {
      state.lecons = state.lecons.map((lecon) => ({
        ...lecon,
        sessions: lecon.sessions.map((audio) => (audio?._id === action.payload._id ? action.payload : audio)),
      }));
    },
  },
  extraReducers: (builder: ActionReducerMapBuilder<LeconsState>) => {
    // Delete leçon
    builder.addCase(deleteLecon.fulfilled, (state, action) => {
      state.lecons = state.lecons.filter((x) => x?._id !== action.payload);
    });

    // Click favorite
    builder.addCase(clickFavorite.fulfilled, (state, action) => {
      state.lecons = state.lecons.map((x) => (x?._id === action.payload._id ? action.payload : x));
      state.favorites = state.favorites.map((x) => (x?._id === action.payload._id ? action.payload : x));
    });

    // View audio
    builder.addCase(viewAudio.fulfilled, (state, action) => {
      state.lecons = state.lecons.map((x) => (x?._id === action.payload._id ? action.payload : x));
      state.favorites = state.favorites.map((x) => (x?._id === action.payload._id ? action.payload : x));
    });

    // Get favorites
    builder.addCase(getFavoriteLecons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getFavoriteLecons.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.favorites = action.payload;
      }
      state.success = true;
    });
    builder.addCase(getFavoriteLecons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Get lecons
    builder.addCase(getAllLecons.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getAllLecons.fulfilled, (state, action) => {
      state.isLoading = false;
      if (action.payload.length > 0) {
        state.lecons = action.payload;
      }
      state.success = true;
    });
    builder.addCase(getAllLecons.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Create leçon
    builder.addCase(createLecon.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createLecon.fulfilled, (state, action) => {
      state.isLoading = false;
      state.lecons.push(action.payload);
      state.success = true;
    });
    builder.addCase(createLecon.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });

    // Update leçon
    builder.addCase(updateLecon.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateLecon.fulfilled, (state, action) => {
      console.log(action.payload);
      state.isLoading = false;
      state.lecons = state.lecons.map((x) => (x?._id === action.payload._id ? action.payload : x));
      state.success = true;
    });
    builder.addCase(updateLecon.rejected, (state, action) => {
      state.isLoading = false;
      state.error = action.error;
      state.success = false;
    });
  },
});

export const leconsActions = leconsSlice.actions;

export { getAllLecons, viewAudio, clickFavorite, getFavoriteLecons, deleteLecon, createLecon, updateLecon };

export default leconsSlice.reducer;
