import { Grid } from "@mui/material";
import React from "react";
import "./Page.scss";
import NewFooter from "./NewFooter";

export type PageTitle =
  | "Loading"
  | "Accueil"
  | "Leçons"
  | "Exercices"
  | "Favoris"
  | "Profil"
  | "Login"
  | "Register"
  | "NotFound";

interface IPage {
  children: React.ReactNode;
  hideFooter?: boolean;
  className?: string;
  style?: React.CSSProperties;
  maxWidth?: string;
  pageTitle?: PageTitle;
  centerAllHorizontally?: boolean;
  header?: JSX.Element;
  noPadding?: boolean;
  fullHeight?: boolean;
}

const Page: React.FC<IPage> = (props: IPage): JSX.Element => {
  const {
    hideFooter,
    className,
    children,
    style,
    maxWidth,
    centerAllHorizontally,
    header,
    noPadding,
    fullHeight,
  } = props;

  return (
    <div
      className={className}
      style={{
        display: "flex",
        flexDirection: "column",
        ...style,
      }}
    >
      {header && (
        <Grid item display="grid" style={{ width: "100%" }}>
          {header}
        </Grid>
      )}
      <Grid
        item
        xs
        display="grid"
        flexGrow={1} // This will allow the content to expand and push the footer down
        paddingBottom="20px"
        overflow="auto"
      >
        <Grid
          container
          direction="column"
          justifyContent={centerAllHorizontally ? "center" : undefined}
          wrap="nowrap"
          gap="20px"
          maxWidth={maxWidth}
          justifySelf={"center"}
        >
          <Grid
            item
            display="grid"
            padding={noPadding ? "0px" : "20px 40px"}
            paddingBottom="50px"
            height={fullHeight ? "100%" : undefined}
          >
            {children}
          </Grid>
        </Grid>
      </Grid>
      {!hideFooter && (
        <footer style={{ width: "100%" }}>
          <NewFooter pageTitle={props.pageTitle} />
        </footer>
      )}
    </div>
  );
};

Page.defaultProps = {
  hideFooter: false,
  className: "page-body",
  style: undefined,
  maxWidth: "1000px",
  centerAllHorizontally: false,
};

export default Page;
