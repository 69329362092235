import React, { useState, useEffect } from "react";
import { GenericPopup } from "./Popup/GenericPopup";
import PrimaryButton from "./PrimaryButton/PrimaryButton";
import { Grid } from "@mui/material";
import NormalText from "../Texts/NormalText";

const PWAInstallButton: React.FC = () => {
  const [deferredPrompt, setDeferredPrompt] = useState<any>(null);
  const [showInstallButton, setShowInstallButton] = useState<boolean>(false);

  useEffect(() => {
    // Vérifie si l'application est déjà installée
    const isInstalled = window.matchMedia("(display-mode: standalone)").matches;
    if (isInstalled) {
      return; // Si l'application est déjà installée, ne rien faire
    }

    /*
    // Vérifie si l'utilisateur a déjà vu la popup aujourd'hui
    const lastPopupDate = localStorage.getItem("lastInstallPopupDate");
    const today = new Date().toISOString().split("T")[0]; // Format YYYY-MM-DD

    if (lastPopupDate === today) {
      return; // Si la popup a déjà été montrée aujourd'hui, ne rien faire
    }
      */

    const handleBeforeInstallPrompt = (event: any) => {
      event.preventDefault(); // Empêche l'affichage automatique de la boîte de dialogue d'installation
      setDeferredPrompt(event); // Stocke l'événement pour un usage ultérieur
      setShowInstallButton(true); // Affiche le bouton d'installation
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstall = () => {
    if (deferredPrompt) {
      deferredPrompt.prompt(); // Affiche la boîte de dialogue d'installation
      deferredPrompt.userChoice.then((choiceResult: any) => {
        if (choiceResult.outcome === "accepted") {
          console.log("PWA installée !");
        } else {
          console.log("L'utilisateur a refusé l'installation.");
        }
        setDeferredPrompt(null); // Réinitialise l'événement
        localStorage.setItem(
          "lastInstallPopupDate",
          new Date().toISOString().split("T")[0]
        ); // Sauvegarde la date de la popup
      });
    }
  };

  if (!showInstallButton) return null; // N'affiche le bouton que si l'installation est possible

  return (
    <GenericPopup
      xs={4}
      heightPopup={"300px"}
      onClose={() => {
        setShowInstallButton(false);
      }}
      title={{
        leftPart: "Installer",
        rightPart: "l'application",
      }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        gap="20px"
        justifyContent={"center"}
        alignItems={"center"}
        padding="20px"
      >
        <Grid item display="grid">
          <NormalText
            color="Black"
            text={`Vous pouvez installer Stoic System !`}
            style={{
              textAlign: "center",
            }}
          />
        </Grid>
        <Grid item display="grid">
          <PrimaryButton
            text="C'est parti !"
            onClick={handleInstall}
          />
        </Grid>
      </Grid>
    </GenericPopup>
  );
};

export default PWAInstallButton;
