import React, { useRef, useState, useEffect } from "react";
import Page from "../components/Page";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { CircularProgress, Grid, IconButton, Tooltip } from "@mui/material";
import NormalText from "../Texts/NormalText";
import Colors from "../utils/Colors";
import ButtonIconWithTooltip from "../components/ButtonIconWithTooltip";
import {
  AddRounded,
  ArrowBackIos,
  ArrowForwardIos,
  Celebration,
  Check,
  CloseRounded,
  DeleteRounded,
  EditRounded,
  FavoriteBorderRounded,
  FavoriteRounded,
  Pause,
  PlayArrow,
  QueryBuilder,
  Replay,
  WorkspacePremium,
} from "@mui/icons-material";
import { current, nanoid } from "@reduxjs/toolkit";
import makeAPIRequest from "../redux/makeAPIRequest";
import useOpenSnackbar from "../redux/useOpenSnackbar";
import useAppDispatch from "../redux/useAppDispatch";
import {
  clickFavorite,
  createLecon,
  deleteLecon,
  getAllLecons,
  leconsActions,
  updateLecon,
  viewAudio,
} from "../redux/leconsSlice";
import useAppSelector from "../redux/useAppSelector";
import PrimaryButton from "../components/PrimaryButton/PrimaryButton";
import { GenericPopup, MyEditor } from "../components/Popup/GenericPopup";
import SecondaryButton from "../components/SecondaryButton/SecondaryButton";
import BasicInput from "../components/BasicInput";
import BasicSelect from "../components/BasicSelect";
import { IExerciseResponse } from "./Exercices";
import { deleteExercise } from "../redux/exercisesSlice";
import { useNavigate } from "react-router-dom";
import useQuery from "../redux/useQuery";

export const audioTypes = [
  "audio/aac",
  "audio/midi",
  "audio/x-midi",
  "audio/mpeg",
  "video/mp3",
  "audio/ogg",
  "audio/wav",
  "audio/webm",
  "audio/3gpp",
  "audio/3gpp2",
  "audio/flac",
];

export const imageTypes = [
  "image/png",
  "image/jpeg",
  "image/gif",
  "image/bmp",
  "image/webp",
  "image/svg+xml",
  "image/tiff",
  "image/vnd.microsoft.icon",
  "image/heif",
  "image/heic",
];

export const ProgressBar: React.FC<{ percentProgression: number }> = (
  props
) => {
  const { percentProgression } = props;

  const progressBarStyle: React.CSSProperties = {
    width: "100%",
    height: "10px",
    backgroundColor: Colors.White,
    borderRadius: "5px",
    overflow: "hidden",
  };

  const progressStyle: React.CSSProperties = {
    width: `${percentProgression}%`,
    height: "10px",
    backgroundColor: Colors.Blue,
    borderRadius: "5px",
    transition: "width 0.3s ease",
  };

  return (
    <div className="progress-bar" style={progressBarStyle}>
      <div className="progress" style={progressStyle}></div>
    </div>
  );
};

export const onglets = [
  "Comprendre",
  "Désirs",
  "Jugements",
  "Actions",
] as const;
export type Onglet = (typeof onglets)[number];

export interface Audio {
  _id?: string;
  title: string;
  audio: string;
  viewed?: boolean;
  lengthInSec?: number;
}

export interface Carte {
  _id?: string;
  title: string;
  content: string;
}

export interface Lecon {
  _id?: string;
  onglet?: Onglet;
  background: string;
  title: string;
  cartes: Carte[];
  description: string;
  favorite?: boolean;
  sessions: Audio[];
  nextSessionId?: string;
  nbSessionsTotal?: number;
  nbSessionsCompleted?: number;
  percentProgression?: number;
}

export const getDurationFromSeconds = (seconds: number) => {
  if (seconds === Number.POSITIVE_INFINITY || seconds === 0) {
    return "? min";
  }
  if (seconds > 60) {
    return `${Math.floor(seconds / 60)} min${
      seconds % 60 > 0 ? ` et ${seconds % 60} sec` : ``
    }`;
  }
  return `${seconds} sec`;
};

export const PopupFullPage: React.FC<{
  zIndex?: number;
  children: React.ReactNode;
  disableBlur?: boolean;
}> = (props) => {
  const overlayStyle: React.CSSProperties = {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: props.disableBlur ? undefined : "rgba(0, 0, 0, 0.5)",
    zIndex: props.zIndex ? props.zIndex - 1 : 9,
    pointerEvents: "auto",
    backdropFilter: props.disableBlur ? undefined : "blur(2px)",
  };

  return (
    <>
      <div className="popup-overlay" style={overlayStyle} />
      <div
        className="popup-full-page"
        style={{
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "100%",
          height: "100%",
          position: "absolute",
          zIndex: props.zIndex,
        }}
      >
        {props.children}
      </div>
    </>
  );
};

PopupFullPage.defaultProps = {
  zIndex: 10,
};

export const DisplayAudio: React.FC<{
  audio: Audio;
  startAuto?: boolean;
  isEditInterface?: boolean;
  fitContent?: boolean;
  onAudioEnd?: () => void;
  onStopAllAudioExceptThis?: () => void;
  isBlackBackground?: boolean;
}> = (props) => {
  const { audio, startAuto, isEditInterface } = props;
  const audioRef = React.useRef<any>(null);
  const [audioState, setAudioState] = React.useState<
    "not-started" | "pause" | "play"
  >("not-started");
  const [duration, setDuration] = React.useState<number>(
    audio?.lengthInSec ? audio.lengthInSec : 0
  );

  const dispatch = useAppDispatch();

  React.useEffect(() => {
    if (startAuto === true) {
      setAudioState("play");
    }
  }, [startAuto]);

  React.useEffect(() => {
    if (!audioRef?.current) {
      return;
    }
    audioRef.current.onpause = () => {
      setAudioState("pause");
    };
    audioRef.current.volume = 0.2;
    if (audioState === "play") {
      if (audioRef?.current?.play) {
        audioRef.current.play();
      }
    }
    if (audioState === "pause") {
      if (audioRef?.current?.pause) {
        audioRef.current.pause();
      }
    }
  }, [audioState, audioRef]);

  return (
    <Grid container direction="column" wrap="nowrap" gap="10px">
      <Grid item display="grid">
        <audio
          id={`audio-controller-${audio?._id}`}
          ref={audioRef}
          src={audio.audio}
          preload={"none"}
          onLoadedData={() => {
            if (audioRef?.current?.duration) {
              setDuration(Math.floor(audioRef.current.duration));
            }
          }}
          onEnded={() => {
            setAudioState("not-started");
            if (!audio.viewed && audio._id && !isEditInterface) {
              dispatch(viewAudio(audio._id));
            }
            if (props.onAudioEnd) {
              props.onAudioEnd();
            }
          }}
        />
      </Grid>
      <Grid
        container
        direction="row"
        wrap="nowrap"
        gap="20px"
        alignItems={"center"}
        justifyContent={isEditInterface ? "center" : "flex-start"}
      >
        <Grid item display="grid">
          <ButtonIconWithTooltip
            tooltipText=""
            Icon={
              <div
                style={{
                  position: "relative",
                }}
              >
                {audio.viewed && !isEditInterface && (
                  <div
                    style={{
                      position: "absolute",
                      top: 3,
                      right: -2,
                      zIndex: 2,
                    }}
                  >
                    <div
                      style={{
                        width: "13px",
                        height: "13px",
                        backgroundColor: Colors.Blue,
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Check
                        style={{
                          width: "9px",
                          height: "9px",
                          color: "white",
                        }}
                      />
                    </div>
                  </div>
                )}
                <div
                  style={{
                    position: "relative",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                    background: `#fff`,
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                >
                  {audioState !== "play" ? (
                    <PlayArrow
                      style={{
                        color: "#000",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  ) : (
                    <Pause
                      style={{
                        color: "#000",
                        width: "24px",
                        height: "24px",
                      }}
                    />
                  )}
                </div>
              </div>
            }
            onClick={() => {
              if (audioState !== "play" && props.onStopAllAudioExceptThis) {
                props.onStopAllAudioExceptThis();
              }
              setAudioState(audioState === "play" ? "pause" : "play");
            }}
          />
        </Grid>
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid
              item
              display="grid"
              className={
                props.isEditInterface ? `edit-lecon-audio-title` : undefined
              }
              style={
                props.fitContent
                  ? {
                      width: "fit-content",
                    }
                  : undefined
              }
            >
              <NormalText
                text={audio.title}
                color={props.isEditInterface ? "Black" : "White"}
                fontSize="14px"
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                alignItems={"center"}
                wrap="nowrap"
                gap="5px"
                style={{ userSelect: "none" }}
              >
                <Grid item display="grid">
                  <QueryBuilder
                    style={{
                      width: "14px",
                      height: "14px",
                      color: Colors.Grey,
                    }}
                  />
                </Grid>
                <Grid item display="grid">
                  <NormalText
                    text={
                      audioRef?.current?.duration
                        ? getDurationFromSeconds(duration)
                        : audio?.lengthInSec
                        ? getDurationFromSeconds(audio.lengthInSec)
                        : "? min"
                    }
                    color={"Grey"}
                    fontSize="12px"
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const HTMLDraw: React.FC<{ html: string }> = (props) => {
  const [adjustedContent, setAdjustedContent] = useState("");

  useEffect(() => {
    if (props.html) {
      const modifiedContent = adjustImageWidth(props.html);
      setAdjustedContent(modifiedContent);
    }
  }, [props.html]);

  function adjustImageWidth(content: string) {
    if (!content) return "";

    // Regex pour rechercher l'attribut de style de l'image avec aspect-ratio
    const styleRegex =
      /(<img.*?style=".*?aspect-ratio:\s*)(\d+)\/(\d+)(.*?".*?>)/gi;

    // Regex pour rechercher les attributs width et height
    const sizeRegex = /(<img.*?)\s*width="(\d+)"\s*height="(\d+)"(.*?>)/gi;

    // Fonction de remplacement pour ajuster l'aspect ratio en fonction de la largeur maximale
    const modifiedContent = content
      .replace(styleRegex, (match, p1, widthRatio, heightRatio, p4) => {
        const maxWidth = 250; // Largeur maximale souhaitée
        const newWidth = maxWidth; // Nouvelle largeur fixée à maxWidth
        const newHeight = Math.round((heightRatio / widthRatio) * maxWidth); // Calcul de la nouvelle hauteur

        // Retourne la balise img avec le nouveau style ajusté
        return `${p1}${newWidth}/${newHeight}${p4}`;
      })
      .replace(sizeRegex, (match, p1, width, height, p4) => {
        const maxWidth = 250; // Largeur maximale souhaitée
        const newWidth = maxWidth; // Nouvelle largeur fixée à maxWidth
        const newHeight = Math.round((height / width) * maxWidth); // Calcul de la nouvelle hauteur

        // Retourne la balise img avec les nouveaux attributs width et height
        return `${p1} width="${newWidth}" height="${newHeight}"${p4}`;
      });

    return modifiedContent;
  }

  return (
    <div
      style={{ maxWidth: "300px" }}
      dangerouslySetInnerHTML={{ __html: adjustedContent }}
    />
  );
};

export const DrawCard: React.FC<{
  card: Lecon;
  onFavoriteClick: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}> = (props) => {
  const snackbarOpenUse = useOpenSnackbar();
  const [isDetailOpened, setIsDetailOpened] = React.useState<boolean>(false);
  const [sessionIdOpened, setSessionIdOpened] = React.useState<
    string | undefined
  >(undefined);
  const dispatch = useAppDispatch();
  const role = useAppSelector((x) => x?.user?.user?.role);
  const isAdmin = role === "admin" ? true : false;
  const sessionOpened = props.card.sessions.find(
    (x) => x?._id === sessionIdOpened
  );
  const nextSessionIndex = props.card.sessions.findIndex(
    (x) => x._id === props.card.nextSessionId
  );
  const [openedCarte, setOpenedCarte] = React.useState<Carte | undefined>(
    undefined
  );

  React.useEffect(() => {
    const id = sessionOpened?._id;
    const section = document?.querySelector(`#audio-${id}`);
    if (section) {
      section?.scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }
  }, [sessionOpened]);
  /* SCROLLER */

  const containerRef = useRef<HTMLDivElement>(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScrollLeft = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: -100, behavior: "smooth" });
      setScrollPosition(containerRef.current.scrollLeft - 100);
    }
  };

  const handleScrollRight = () => {
    if (containerRef.current) {
      containerRef.current.scrollBy({ left: 100, behavior: "smooth" });
      setScrollPosition(containerRef.current.scrollLeft + 100);
    }
  };

  return (
    <>
      {openedCarte && (
        <PopupFullPage zIndex={12} disableBlur={true}>
          <Grid
            container
            className="background-popup-details-card"
            direction="column"
            wrap="nowrap"
            width="100%"
            padding="25px"
            height="100%"
            style={{
              backgroundColor: `#fff`,
            }}
          >
            <Grid
              item
              display="grid"
              alignSelf={"flex-start"}
              padding="5px"
              onClick={() => {
                setOpenedCarte(undefined);
              }}
              style={{
                background: `rgba(65, 65, 65, 0.8)`,
                borderRadius: "50%",
                zIndex: 1,
                cursor: "pointer",
              }}
            >
              <ButtonIconWithTooltip
                Icon={
                  <CloseRounded
                    style={{
                      color: "white",
                      width: "25px",
                      height: "25px",
                    }}
                  />
                }
                tooltipText=""
                onClick={() => {
                  return;
                }}
              />
            </Grid>
            <Grid
              item
              display="grid"
              justifyContent={"center"}
              alignItems={"center"}
              marginTop="30px"
            >
              <HTMLDraw html={openedCarte?.content} />
            </Grid>
          </Grid>
        </PopupFullPage>
      )}
      {isDetailOpened && (
        <PopupFullPage zIndex={10}>
          <Grid
            container
            className="background-popup-details-card"
            direction="column"
            wrap="nowrap"
            width="100%"
            height="100%"
            style={{
              backgroundColor: `#000`,
              maxHeight: "100vh",
              overflow: "auto",
            }}
          >
            <div
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                zIndex: 2,
                width: "100%",
              }}
            >
              <Grid
                container
                direction="row"
                wrap="nowrap"
                gap="20px"
                padding="20px"
                justifyContent={"space-between"}
              >
                <Grid
                  item
                  display="grid"
                  justifyContent={"center"}
                  alignItems={"center"}
                  padding="5px"
                  onClick={() => {
                    setIsDetailOpened(false);
                  }}
                  style={{
                    background: `rgba(65, 65, 65, 0.8)`,
                    borderRadius: "50%",
                    zIndex: 1,
                  }}
                >
                  <ButtonIconWithTooltip
                    Icon={
                      <CloseRounded
                        style={{
                          color: "white",
                          width: "25px",
                          height: "25px",
                        }}
                      />
                    }
                    tooltipText=""
                    onClick={() => {
                      return;
                    }}
                  />
                </Grid>

                <Grid
                  item
                  display="grid"
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={() => {
                    props.onFavoriteClick();
                  }}
                  style={{
                    background: `rgba(65, 65, 65, 0.8)`,
                    width: "35px",
                    height: "35px",
                    borderRadius: "50%",
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                >
                  <ButtonIconWithTooltip
                    Icon={
                      props.card.favorite ? (
                        <FavoriteRounded
                          style={{
                            color: "#ff405d",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      ) : (
                        <FavoriteBorderRounded
                          style={{
                            color: "white",
                            width: "25px",
                            height: "25px",
                          }}
                        />
                      )
                    }
                    tooltipText=""
                    onClick={() => {
                      return;
                    }}
                  />
                </Grid>
              </Grid>
            </div>
            <Grid item display="grid">
              <Grid
                container
                direction="column"
                wrap="nowrap"
                width="100%"
                height="100%"
                style={{
                  backgroundImage: `url(${props.card.background})`,
                  backgroundPosition: "center",
                  backgroundSize: "cover",
                  backgroundRepeat: "no-repeat",
                  position: "relative",
                }}
              >
                <div
                  style={{
                    position: "absolute",
                    bottom: 0,
                    zIndex: 0,
                    right: 0,
                    width: "100%",
                    height: "210px",
                    backgroundImage: `linear-gradient(180deg, rgba(0,0,0,0), rgba(0,0,0,1) 40px)`,
                  }}
                />
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  padding="25px"
                  height="470px"
                  justifyContent={"space-between"}
                  gap="20px"
                  style={{
                    zIndex: 1,
                  }}
                >
                  <Grid item display="grid" paddingTop="40px">
                    <Grid
                      container
                      direction="column"
                      alignItems={"center"}
                      wrap="nowrap"
                      gap="10px"
                      onClick={() => {
                        if (nextSessionIndex !== -1) {
                          setSessionIdOpened(props.card.nextSessionId);
                        }
                      }}
                      style={{
                        userSelect: "none",
                        cursor: nextSessionIndex !== -1 ? "pointer" : "default",
                      }}
                    >
                      <Grid
                        item
                        display="grid"
                        justifyContent={"center"}
                        alignItems={"center"}
                        style={{
                          background: `rgba(150, 150, 150, 0.7)`,
                          width: "70px",
                          height: "70px",
                          borderRadius: "50%",
                        }}
                      >
                        {nextSessionIndex !== -1 ? (
                          <PlayArrow
                            style={{
                              color: Colors.White,
                              width: "45px",
                              height: "45px",
                            }}
                          />
                        ) : (
                          <Celebration
                            style={{
                              color: Colors.White,
                              width: "45px",
                              height: "45px",
                            }}
                          />
                        )}
                      </Grid>
                      <Grid item display="grid">
                        <NormalText
                          text={
                            nextSessionIndex !== -1
                              ? `Continuer avec la session ${
                                  nextSessionIndex + 1
                                }`
                              : `Leçon terminée !`
                          }
                          fontSize="16px"
                          color="White"
                          fontWeight={400}
                          style={{
                            textAlign: "center",
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item display="grid">
                    <Grid container direction="column" wrap="nowrap" gap="10px">
                      <Grid item display="grid">
                        <NormalText
                          text={props.card.title}
                          fontSize="24px"
                          color="White"
                          fontWeight={400}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <NormalText
                          text={props.card.description}
                          fontSize="14px"
                          color="White"
                          fontWeight={400}
                        />
                      </Grid>
                      {props.card?.cartes?.length > 0 && (
                        <Grid item display="grid" marginTop="50px">
                          <Grid
                            container
                            direction="row"
                            wrap="nowrap"
                            gap="10px"
                            alignItems="center"
                          >
                            <Grid item display="grid">
                              <IconButton
                                onClick={handleScrollLeft}
                                disabled={false}
                                style={{
                                  color: Colors.DarkGrey,
                                }}
                              >
                                <ArrowBackIos style={{ fontSize: "16px" }} />
                              </IconButton>
                            </Grid>
                            <Grid
                              item
                              xs
                              display="grid"
                              justifyContent={"center"}
                            >
                              <div
                                style={{
                                  overflow: "hidden",
                                  display: "flex",
                                  flex: 1,
                                }}
                              >
                                <Grid
                                  container
                                  direction="row"
                                  wrap="nowrap"
                                  gap="10px"
                                  ref={containerRef}
                                  style={{
                                    overflow: "hidden",
                                    scrollBehavior: "smooth",
                                  }}
                                  onScroll={() =>
                                    setScrollPosition(
                                      containerRef?.current?.scrollLeft
                                        ? containerRef.current.scrollLeft
                                        : 0
                                    )
                                  }
                                >
                                  {props.card?.cartes?.length > 0 &&
                                    props.card.cartes.map((carte, index) => (
                                      <Grid item key={index} display="grid">
                                        <div
                                          className="button-nobreak"
                                          style={{
                                            backgroundColor: Colors.DarkGrey,
                                            borderRadius: "10px",
                                            height: "40px",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            padding: "5px 10px",
                                          }}
                                          onClick={() => {
                                            setOpenedCarte(carte);
                                          }}
                                        >
                                          <NormalText
                                            text={carte.title}
                                            color="White"
                                            fontSize="12px"
                                            fontWeight={400}
                                            style={{
                                              whiteSpace: "nowrap",
                                            }}
                                          />
                                        </div>
                                      </Grid>
                                    ))}
                                </Grid>
                              </div>
                            </Grid>
                            <Grid item display="grid">
                              <IconButton
                                onClick={handleScrollRight}
                                disabled={false}
                                style={{
                                  color: Colors.DarkGrey,
                                }}
                              >
                                <ArrowForwardIos style={{ fontSize: "16px" }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                      <Grid item display="grid" marginTop="10px">
                        <ProgressBar
                          percentProgression={Math.round(
                            props.card.percentProgression as number
                          )}
                        />
                      </Grid>
                      <Grid item display="grid">
                        <NormalText
                          text={`${
                            props.card.nbSessionsTotal
                          } sessions  ⋅  ${Math.round(
                            props.card.percentProgression as number
                          )}% complété`}
                          fontSize="14px"
                          color="White"
                          fontWeight={400}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid
              item
              display="grid"
              style={{
                backgroundColor: Colors.Black,
              }}
            >
              <Grid
                container
                direction="column"
                wrap="nowrap"
                gap="15px"
                paddingLeft="25px"
                paddingRight="25px"
                paddingBottom="25px"
              >
                {props.card.sessions.map((audio, index, all) => {
                  return (
                    <Grid
                      item
                      display="grid"
                      key={audio._id}
                      id={`audio-` + audio._id}
                    >
                      <DisplayAudio
                        startAuto={
                          sessionIdOpened === audio?._id ? true : false
                        }
                        audio={audio}
                        onStopAllAudioExceptThis={() => {
                          const allAudioExceptThis = all.filter(
                            (x) => x?._id !== audio?._id
                          );
                          allAudioExceptThis.forEach((a) => {
                            const qSelector = `#audio-controller-${a?._id}`;
                            const audioSelected =
                              document?.querySelector<HTMLAudioElement>(
                                qSelector
                              );

                            if (
                              audioSelected &&
                              audioSelected instanceof HTMLAudioElement &&
                              audioSelected.currentTime > 0 &&
                              !audioSelected.paused &&
                              !audioSelected.ended &&
                              audioSelected.readyState > 2
                            ) {
                              audioSelected.pause();
                              audioSelected.currentTime = 0;
                            }
                          });
                        }}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </Grid>
          </Grid>
        </PopupFullPage>
      )}
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent={"space-between"}
        justifySelf="center"
        padding="20px"
        onClick={() => {
          setIsDetailOpened(true);
        }}
        style={{
          height: "400px",
          width: "260px",
          backgroundImage: `url(${props.card.background})`,
          backgroundPosition: "center",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          border: "1px solid rgba(105, 105, 105, 0.5)",
          borderRadius: "16px",
          userSelect: "none",
          cursor: "pointer",
          zIndex: 0,
        }}
      >
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            gap="10px"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              item
              display="grid"
              style={{
                background: `rgba(65, 65, 65, 0.8)`,
                borderRadius: "16px",
                padding: "5px 10px",
              }}
            >
              <NormalText
                text={props.card.onglet ? props.card.onglet.toUpperCase() : ""}
                color="White"
                fontSize="10px"
                fontWeight={600}
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                gap="10px"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {props.onDeleteClick && isAdmin && (
                  <Grid
                    item
                    display="grid"
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.onDeleteClick) {
                        props.onDeleteClick();
                      }
                    }}
                    style={{
                      background: `rgba(65, 65, 65, 0.8)`,
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <ButtonIconWithTooltip
                      Icon={
                        <DeleteRounded
                          style={{
                            color: Colors.White,
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      }
                      tooltipText=""
                      onClick={() => {
                        if (props.card._id) {
                          dispatch(deleteLecon(props.card._id))
                            .unwrap()
                            .then((res) => {
                              snackbarOpenUse.success(
                                "La leçon a été supprimée avec succès.",
                                res
                              );
                            })
                            .catch((err) => {
                              snackbarOpenUse.error(
                                "La leçon n'a pas pu être supprimée.",
                                err
                              );
                            });
                        }
                      }}
                    />
                  </Grid>
                )}
                {props.onEditClick && isAdmin && (
                  <Grid
                    item
                    display="grid"
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.onEditClick) {
                        props.onEditClick();
                      }
                    }}
                    style={{
                      background: `rgba(65, 65, 65, 0.8)`,
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <ButtonIconWithTooltip
                      Icon={
                        <EditRounded
                          style={{
                            color: Colors.White,
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      }
                      tooltipText=""
                      onClick={() => {
                        return;
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  display="grid"
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={(e) => {
                    e.stopPropagation();
                    props.onFavoriteClick();
                  }}
                  style={{
                    background: `rgba(65, 65, 65, 0.8)`,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                >
                  <ButtonIconWithTooltip
                    Icon={
                      props.card.favorite ? (
                        <FavoriteRounded
                          style={{
                            color: "#ff405d",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      ) : (
                        <FavoriteBorderRounded
                          style={{
                            color: "white",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      )
                    }
                    tooltipText=""
                    onClick={() => {
                      return;
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="10px">
            <Grid item display="grid">
              <NormalText
                text={props.card.title}
                fontSize="20px"
                color="White"
                fontWeight={400}
              />
            </Grid>
            <Grid item display="grid">
              <Grid container direction="column" wrap="nowrap" gap="5px">
                <Grid item display="grid">
                  <NormalText
                    text={
                      Math.round(
                        props.card.percentProgression as number
                      ).toString() + "%"
                    }
                    color="White"
                    fontSize="14px"
                    fontWeight={400}
                  />
                </Grid>
                <Grid item display="grid">
                  <div
                    style={{
                      width: Math.round(
                        (220 *
                          Math.round(props.card.percentProgression as number)) /
                          100
                      ),
                      height: "5px",
                      backgroundColor: "white",
                      borderRadius: "3px",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export const DrawExerciseCard: React.FC<{
  card: IExerciseResponse;
  onFavoriteClick: () => void;
  onEditClick?: () => void;
  onDeleteClick?: () => void;
}> = (props) => {
  const snackbarOpenUse = useOpenSnackbar();
  const dispatch = useAppDispatch();
  const user = useAppSelector((x) => x?.user?.user);
  const role = user?.role;
  const isAdmin = role === "admin" ? true : false;
  const navigate = useNavigate();

  return (
    <>
      <Grid
        container
        direction="column"
        wrap="nowrap"
        justifyContent={"space-between"}
        justifySelf="center"
        padding="20px"
        style={{
          height: "250px",
          width: "260px",
          border: "1px solid rgba(105, 105, 105, 0.5)",
          borderRadius: "16px",
          backgroundColor: "rgba(255, 255, 255, 0.3)",
          userSelect: "none",
          zIndex: 0,
        }}
      >
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            gap="10px"
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid
              item
              display="grid"
              style={{
                background: `rgba(65, 65, 65, 0.8)`,
                borderRadius: "16px",
                padding: "5px 10px",
              }}
            >
              <NormalText
                text={"EXERCICE"}
                color="White"
                fontSize="10px"
                fontWeight={600}
              />
            </Grid>
            <Grid item display="grid">
              <Grid
                container
                direction="row"
                wrap="nowrap"
                gap="10px"
                justifyContent={"center"}
                alignItems={"center"}
              >
                {props.onDeleteClick && isAdmin && (
                  <Grid
                    item
                    display="grid"
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.onDeleteClick) {
                        props.onDeleteClick();
                      }
                    }}
                    style={{
                      background: `rgba(65, 65, 65, 0.8)`,
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <ButtonIconWithTooltip
                      Icon={
                        <DeleteRounded
                          style={{
                            color: Colors.White,
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      }
                      tooltipText=""
                      onClick={() => {
                        if (props.card._id) {
                          dispatch(deleteExercise(props.card._id))
                            .unwrap()
                            .then((res) => {
                              snackbarOpenUse.success(
                                "L'exercice a été supprimé avec succès.",
                                res
                              );
                            })
                            .catch((err) => {
                              snackbarOpenUse.error(
                                "L'exercice n'a pas pu être supprimée.",
                                err
                              );
                            });
                        }
                      }}
                    />
                  </Grid>
                )}
                {props.onEditClick && isAdmin && (
                  <Grid
                    item
                    display="grid"
                    justifyContent={"center"}
                    alignItems={"center"}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (props.onEditClick) {
                        props.onEditClick();
                      }
                    }}
                    style={{
                      background: `rgba(65, 65, 65, 0.8)`,
                      width: "25px",
                      height: "25px",
                      borderRadius: "50%",
                      zIndex: 1,
                      cursor: "pointer",
                    }}
                  >
                    <ButtonIconWithTooltip
                      Icon={
                        <EditRounded
                          style={{
                            color: Colors.White,
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      }
                      tooltipText=""
                      onClick={() => {
                        return;
                      }}
                    />
                  </Grid>
                )}
                <Grid
                  item
                  display="grid"
                  justifyContent={"center"}
                  alignItems={"center"}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!user?.subscribed && props.card.needSubscription) {
                      snackbarOpenUse.error(
                        "Ce contenu est exclusif pour les abonnés."
                      );
                      return;
                    }
                    props.onFavoriteClick();
                  }}
                  style={{
                    background: `rgba(65, 65, 65, 0.8)`,
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    zIndex: 1,
                    cursor: "pointer",
                  }}
                >
                  <ButtonIconWithTooltip
                    Icon={
                      props.card.favorite ? (
                        <FavoriteRounded
                          style={{
                            color: "#ff405d",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      ) : (
                        <FavoriteBorderRounded
                          style={{
                            color: "white",
                            width: "18px",
                            height: "18px",
                          }}
                        />
                      )
                    }
                    tooltipText=""
                    onClick={() => {
                      return;
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            justifyContent={"space-between"}
            wrap="nowrap"
          >
            <Grid item xs display="grid">
              <Grid container direction="column" wrap="nowrap">
                <Grid item display="grid">
                  <NormalText
                    text={props.card.title}
                    fontSize="24px"
                    color="White"
                    fontWeight={400}
                  />
                </Grid>
                <Grid item display="grid" marginTop="10px">
                  <NormalText
                    text={props.card.description}
                    fontSize="14px"
                    color="White"
                    fontWeight={400}
                  />
                </Grid>
                <Grid item display="grid">
                  <Grid
                    container
                    direction="row-reverse"
                    wrap="nowrap"
                    justifyContent={"space-between"}
                    alignItems={"flex-end"}
                  >
                    <Grid item display="grid">
                      <Grid
                        container
                        direction="row"
                        wrap="nowrap"
                        gap="5px"
                        alignItems={"center"}
                      >
                        {props.card?.needSubscription && (
                          <Grid item display="grid">
                            <Tooltip
                              title={
                                user?.subscribed
                                  ? "Exclusif aux abonnés !"
                                  : "Cet exercice est exclusif aux membres abonnés, cliquez ici pour vous abonner !"
                              }
                              onClick={(e) => {
                                e.stopPropagation();
                                snackbarOpenUse.error(
                                  "Fonctionnalité en cours de développement, elle permettra de s'abonner."
                                );
                              }}
                            >
                              <WorkspacePremium
                                style={{
                                  fontSize: "20px",
                                  color: "#daa520",
                                }}
                              />
                            </Tooltip>
                          </Grid>
                        )}
                        <Grid item display="grid">
                          <SecondaryButton
                            onClick={() => {
                              if (props.card?.needSubscription) {
                                if (!user?.subscribed) {
                                  snackbarOpenUse.error(
                                    "Ce contenu est exclusif pour les abonnés."
                                  );
                                  return;
                                }
                              }
                              navigate(`/exercice/${props.card?._id}`);
                            }}
                            text="Lancer"
                            style={{ fontSize: "12px", height: "30px" }}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item display="grid">
                      <Grid container direction="column" wrap="nowrap">
                        <Grid item display="grid" marginTop="20px">
                          <NormalText
                            text={
                              props.card.nbCompleted === 0
                                ? `À compléter`
                                : `Complété ${props.card.nbCompleted} fois`
                            }
                            fontSize="12px"
                            color="White"
                            fontWeight={400}
                          />
                        </Grid>
                        <Grid item display="grid">
                          <NormalText
                            text={
                              "Durée : " +
                              getDurationFromSeconds(
                                props.card.durationEstimation
                              )
                            }
                            fontSize="12px"
                            color="White"
                            fontWeight={400}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

const initLecon = (): Lecon => {
  return {
    background: "https://picture.stoic-system.com/stoicisme.jpg",
    description: "",
    onglet: "Comprendre",
    cartes: [],
    sessions: [],
    title: "",
  };
};

const cleanLeconBeforeSend = (lecon: Lecon): Lecon => {
  return {
    ...lecon,
    sessions: lecon.sessions.map((s) => {
      return { ...s, _id: undefined };
    }),
    cartes: lecon.cartes.map((s) => {
      return { ...s, _id: undefined };
    }),
    _id: undefined,
    background: lecon.background ? lecon.background : (undefined as any),
  };
};

const cleanLeconBeforePut = (lecon: Lecon): Lecon => {
  return {
    _id: lecon._id,
    onglet: lecon.onglet,
    cartes: lecon.cartes.map((s) => {
      return {
        _id: s?._id && s._id.length > 8 ? s._id : undefined,
        title: s.title,
        content: s.content,
      };
    }),
    background: lecon.background,
    description: lecon.description,
    title: lecon.title,
    sessions: lecon.sessions.map((s) => {
      return {
        _id: s?._id && s._id.length > 8 ? s._id : undefined,
        audio: s.audio,
        title: s.title,
        lengthInSec: s.lengthInSec,
      };
    }),
  };
};

export const EditAudio: React.FC<{
  audio: Audio;
  onClose: () => void;
  onSubmit: (newAudio: Audio) => void;
}> = (props) => {
  const { audio } = props;
  const [newAudio, setNewAudio] = React.useState<Audio>(props.audio);
  const snackbarOpenUse = useOpenSnackbar();

  return (
    <GenericPopup
      xs={6}
      onClose={props.onClose}
      title={{
        leftPart: "Modifier",
        rightPart: "mon audio",
      }}
    >
      <Grid
        container
        direction="column"
        wrap="nowrap"
        padding="20px"
        gap="20px"
        alignItems={"center"}
      >
        <Grid item display="grid">
          <Grid container direction="column" wrap="nowrap" gap="15px">
            <Grid item xs display="grid">
              <BasicInput
                title="Titre"
                colorTitle={"Black"}
                placeholder=""
                style={{
                  maxWidth: "300px",
                }}
                value={newAudio.title}
                onChange={(e) => {
                  setNewAudio((a) => {
                    return { ...a, title: e.target.value };
                  });
                }}
              />
            </Grid>
            <Grid item xs display="grid">
              <BasicInput
                title="Durée (en secondes)"
                colorTitle={"Black"}
                placeholder=""
                style={{
                  maxWidth: "300px",
                }}
                value={newAudio.lengthInSec}
                onChange={(e) => {
                  setNewAudio((a) => {
                    const result = e.target.value;
                    if (result) {
                      const parsed = parseInt(result);
                      if (parsed && !isNaN(parsed)) {
                        return { ...a, lengthInSec: parsed };
                      }
                    }
                    return { ...a, lengthInSec: 0 };
                  });
                }}
              />
            </Grid>
            <Grid item display="grid" alignSelf="center">
              <input
                type="file"
                name="file-audio"
                id="file-audio"
                accept="audio/*,video/mp3"
                style={{
                  opacity: 0,
                  zIndex: -1,
                  position: "absolute",
                }}
                onChange={(e) => {
                  const fileReader = new FileReader();

                  if (e?.target?.files?.[0]) {
                    if (e.target.files[0].size >= 400000000) {
                      snackbarOpenUse.error("Le fichier est trop lourd.");
                    } else if (!audioTypes.includes(e.target.files[0].type)) {
                      snackbarOpenUse.error(
                        "Le fichier est au mauvais format, ça doit être un audio."
                      );
                    } else {
                      fileReader.readAsDataURL(e.target.files[0]);
                      fileReader.onload = (event) => {
                        const attachment = event?.target?.result as string;
                        const attachment_name = e?.target?.files?.[0]
                          ?.name as string;
                        setNewAudio((x) => {
                          return {
                            ...x,
                            audio: attachment,
                            title: attachment_name,
                          };
                        });
                      };
                    }
                  }
                }}
              />
              <label htmlFor="file-audio">
                <NormalText
                  text="Mettre à jour mon audio"
                  fontSize="12px"
                  fontWeight={600}
                  color="Black"
                  className="button-nobreak"
                />
              </label>
            </Grid>
          </Grid>
        </Grid>
        <Grid item display="grid">
          <Grid
            container
            direction="row"
            gap="10px"
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Grid item display="grid">
              <SecondaryButton
                text={"Annuler"}
                onClick={() => {
                  if (props.onClose) {
                    props.onClose();
                  }
                }}
              />
            </Grid>
            <Grid item display="grid">
              <PrimaryButton
                text={"Modifier"}
                onClick={() => {
                  if (props.onSubmit) {
                    props.onSubmit(newAudio);
                    props.onClose();
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </GenericPopup>
  );
};

const initCarte = (): Carte => {
  return {
    _id: nanoid(8),
    title: "",
    content: "",
  };
};

const CreateOrEditCarte: React.FC<{
  carte?: Carte;
  isEdit?: boolean;
  onSubmit: (newCarte: Carte) => void;
  onClose?: () => void;
}> = (props) => {
  const [carte, setCarte] = React.useState<Carte>(initCarte());
  const snackbarOpenUse = useOpenSnackbar();

  React.useEffect(() => {
    if (props.isEdit && props.carte) {
      setCarte(props.carte);
    } else {
      return;
    }
  }, [props.isEdit, props.carte]);

  if (!carte) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "ma carte",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {carte && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="15px"
                  alignItems={"center"}
                >
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Titre"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={carte.title}
                      onChange={(e) => {
                        setCarte((l) => {
                          return { ...l, title: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs display="grid" marginTop="10px">
                    <div className="ck-editor-wrapper">
                      <MyEditor
                        data={carte.content}
                        onChange={(html) =>
                          setCarte((l) => {
                            return { ...l, content: html };
                          })
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid" marginTop="15px">
                <Grid
                  container
                  direction="row"
                  gap="10px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <SecondaryButton
                      text={"Annuler"}
                      onClick={() => {
                        if (props.onClose) {
                          props.onClose();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <PrimaryButton
                      text={props.isEdit ? "Modifier" : "Créer"}
                      onClick={() => {
                        if (props.onSubmit) {
                          props.onSubmit(carte);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const CreateOrEditLecon: React.FC<{
  lecon?: Lecon;
  isEdit?: boolean;
  onSubmit: (newLecon: Lecon) => void;
  onClose?: () => void;
}> = (props) => {
  const [addCarteOpen, setAddCarteOpen] = React.useState(false);
  const [editCarteOpen, setEditCarteOpen] = React.useState<Carte | undefined>(
    undefined
  );

  const [editAudioOpen, setEditAudioOpen] = React.useState<Audio | undefined>(
    undefined
  );
  const [lecon, setLecon] = React.useState<Lecon>(initLecon());
  const snackbarOpenUse = useOpenSnackbar();

  const onDragEnd = (result: any) => {
    if (!result.destination) return;

    const items = Array.from(lecon.sessions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setLecon((prevLecon) => ({
      ...prevLecon,
      sessions: items,
    }));
  };

  React.useEffect(() => {
    if (props.isEdit && props.lecon) {
      setLecon(props.lecon);
    } else {
      return;
    }
  }, [props.isEdit, props.lecon]);

  if (!lecon) {
    return <></>;
  }
  return (
    <>
      <GenericPopup
        title={{
          leftPart: props.isEdit ? "Modifier" : "Créer",
          rightPart: "ma leçon",
        }}
        onClose={() => {
          if (props.onClose) {
            props.onClose();
          }
        }}
      >
        <>
          {addCarteOpen && (
            <CreateOrEditCarte
              onSubmit={(newCarte) => {
                setLecon((state) => {
                  return {
                    ...state,
                    cartes:
                      !state.cartes || state.cartes.length === 0
                        ? [newCarte]
                        : [...state.cartes, newCarte],
                  };
                });
                setAddCarteOpen(false);
              }}
              carte={undefined}
              isEdit={false}
              onClose={() => setAddCarteOpen(false)}
            />
          )}
          {editCarteOpen && (
            <CreateOrEditCarte
              onSubmit={(newCarte) => {
                setLecon((state) => {
                  return {
                    ...state,
                    cartes:
                      state.cartes && state.cartes.length > 0
                        ? state.cartes.map((carte) => {
                            if (carte?._id !== newCarte?._id) {
                              return carte;
                            }
                            return newCarte;
                          })
                        : [],
                  };
                });
                setEditCarteOpen(undefined);
              }}
              carte={editCarteOpen}
              isEdit={true}
              onClose={() => setEditCarteOpen(undefined)}
            />
          )}
          {editAudioOpen && (
            <EditAudio
              audio={editAudioOpen}
              onClose={() => {
                setEditAudioOpen(undefined);
              }}
              onSubmit={(newAudio) => {
                setLecon((l) => {
                  return {
                    ...l,
                    sessions: l.sessions.map((s) => {
                      if (s._id === newAudio._id) {
                        return newAudio;
                      }
                      return s;
                    }),
                  };
                });
              }}
            />
          )}
          {lecon && (
            <Grid
              container
              direction="column"
              wrap="nowrap"
              padding="20px"
              gap="20px"
              alignItems={"center"}
            >
              <Grid item display="grid">
                <Grid
                  container
                  direction="column"
                  wrap="nowrap"
                  gap="15px"
                  alignItems={"center"}
                >
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Titre"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={lecon.title}
                      onChange={(e) => {
                        setLecon((l) => {
                          return { ...l, title: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs display="grid">
                    <BasicInput
                      title="Description"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={lecon.description}
                      onChange={(e) => {
                        setLecon((l) => {
                          return { ...l, description: e.target.value };
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs display="grid">
                    <BasicSelect
                      options={onglets.map((x) => {
                        return { label: x, value: x };
                      })}
                      title="Onglet"
                      colorTitle={"Black"}
                      placeholder=""
                      centerTitle={true}
                      value={lecon.onglet}
                      onChange={(e) => {
                        if (e.target.value) {
                          setLecon((l) => {
                            return { ...l, onglet: e.target.value as any };
                          });
                        }
                      }}
                    />
                  </Grid>
                  <Grid item display="grid" marginTop="20px">
                    <Grid
                      container
                      direction="row"
                      gap="50px"
                      justifyContent={"center"}
                    >
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="10px"
                          alignItems={"center"}
                        >
                          <Grid item display="grid">
                            <NormalText
                              text="Ajouter une carte"
                              fontSize="12px"
                              fontWeight={600}
                              color="Black"
                              className="button-nobreak"
                              onClick={() => {
                                setAddCarteOpen(true);
                              }}
                            />
                          </Grid>
                          {lecon.cartes && lecon.cartes.length > 0 ? (
                            <Grid item display="grid">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                gap="5px"
                              >
                                {lecon.cartes.map((carte, index) => {
                                  return (
                                    <Grid item display="grid" key={carte._id}>
                                      <Grid
                                        container
                                        direction="row"
                                        gap="10px"
                                        justifyContent={"space-between"}
                                        alignItems={"center"}
                                        wrap="nowrap"
                                      >
                                        <Grid item display="grid">
                                          <NormalText
                                            text={carte.title}
                                            color="Black"
                                            fontSize="14px"
                                            fontWeight={400}
                                          />
                                        </Grid>

                                        <Grid item display="grid">
                                          <Grid
                                            container
                                            direction="row"
                                            gap="5px"
                                          >
                                            <Grid item display="grid">
                                              <ButtonIconWithTooltip
                                                Icon={
                                                  <EditRounded
                                                    style={{
                                                      color: Colors.Black,
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  setEditCarteOpen(carte);
                                                }}
                                                tooltipText="Modifier"
                                              />
                                            </Grid>
                                            <Grid item display="grid">
                                              <ButtonIconWithTooltip
                                                Icon={
                                                  <DeleteRounded
                                                    style={{
                                                      color: Colors.Black,
                                                      width: "20px",
                                                      height: "20px",
                                                    }}
                                                  />
                                                }
                                                onClick={() => {
                                                  setLecon((l) => {
                                                    return {
                                                      ...l,
                                                      cartes: l.cartes.filter(
                                                        (s) =>
                                                          s._id !== carte._id
                                                      ),
                                                    };
                                                  });
                                                }}
                                                tooltipText="Supprimer"
                                              />
                                            </Grid>
                                          </Grid>
                                        </Grid>
                                      </Grid>
                                    </Grid>
                                  );
                                })}
                              </Grid>
                            </Grid>
                          ) : (
                            <Grid item display="grid">
                              <NormalText
                                text="Aucune carte."
                                color="Black"
                                fontSize="12px"
                                fontWeight={400}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="10px"
                          alignItems={"center"}
                        >
                          <Grid item display="grid">
                            <input
                              type="file"
                              name="file"
                              id="file"
                              accept="image/*"
                              style={{
                                opacity: 0,
                                zIndex: -1,
                                position: "absolute",
                              }}
                              onChange={(e) => {
                                const fileReader = new FileReader();

                                if (e?.target?.files?.[0]) {
                                  if (e.target.files[0].size >= 400000000) {
                                    snackbarOpenUse.error(
                                      "Le fichier est trop lourd."
                                    );
                                  } else if (
                                    !imageTypes.includes(e.target.files[0].type)
                                  ) {
                                    snackbarOpenUse.error(
                                      "Le fichier est au mauvais format, ça doit être une image."
                                    );
                                  } else {
                                    fileReader.readAsDataURL(e.target.files[0]);
                                    fileReader.onload = (event) => {
                                      const attachment = event?.target
                                        ?.result as string;
                                      //const attachment_name = e?.target?.files?.[0]
                                      //  ?.name as string;
                                      // Add audio title
                                      setLecon((l) => {
                                        return {
                                          ...l,
                                          background: attachment,
                                        };
                                      });
                                    };
                                  }
                                }
                              }}
                            />
                            <label htmlFor="file">
                              <NormalText
                                text="Changer l'image de fond"
                                fontSize="12px"
                                fontWeight={600}
                                color="Black"
                                className="button-nobreak"
                              />
                            </label>
                          </Grid>
                          {lecon.background && (
                            <Grid item display="grid">
                              <img
                                src={lecon.background}
                                style={{
                                  maxWidth: "200px",
                                }}
                              />
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                      <Grid item display="grid">
                        <Grid
                          container
                          direction="column"
                          wrap="nowrap"
                          gap="10px"
                          alignItems={"center"}
                        >
                          <Grid item display="grid">
                            <input
                              type="file"
                              name="file-audio"
                              id="file-audio"
                              accept="audio/*,video/mp3"
                              style={{
                                opacity: 0,
                                zIndex: -1,
                                position: "absolute",
                              }}
                              onChange={(e) => {
                                const fileReader = new FileReader();

                                if (e?.target?.files?.[0]) {
                                  if (e.target.files[0].size >= 400000000) {
                                    snackbarOpenUse.error(
                                      "Le fichier est trop lourd."
                                    );
                                  } else if (
                                    !audioTypes.includes(e.target.files[0].type)
                                  ) {
                                    snackbarOpenUse.error(
                                      "Le fichier est au mauvais format, ça doit être un audio."
                                    );
                                  } else {
                                    fileReader.readAsDataURL(e.target.files[0]);
                                    fileReader.onload = (event) => {
                                      const attachment = event?.target
                                        ?.result as string;
                                      const attachment_name = e?.target
                                        ?.files?.[0]?.name as string;
                                      // Add audio title
                                      setLecon((l) => {
                                        return {
                                          ...l,
                                          sessions: [
                                            ...l.sessions,
                                            {
                                              _id: nanoid(8),
                                              audio: attachment,
                                              title: attachment_name,
                                            },
                                          ],
                                        };
                                      });
                                    };
                                  }
                                }
                              }}
                            />
                            <label htmlFor="file-audio">
                              <NormalText
                                text="Ajouter une session"
                                fontSize="12px"
                                fontWeight={600}
                                color="Black"
                                className="button-nobreak"
                              />
                            </label>
                          </Grid>
                          {/* ICI */}
                          {!lecon?.sessions ||
                            (lecon?.sessions?.length == 0 && (
                              <Grid item display="grid">
                                <NormalText
                                  text="Aucune session."
                                  color="Black"
                                  fontSize="12px"
                                  fontWeight={400}
                                />
                              </Grid>
                            ))}
                          {lecon?.sessions?.length > 0 && (
                            <Grid item display="grid">
                              <Grid
                                container
                                direction="column"
                                wrap="nowrap"
                                gap="15px"
                              >
                                {lecon?.sessions?.length > 0 && (
                                  <DragDropContext onDragEnd={onDragEnd}>
                                    <Droppable droppableId="sessions">
                                      {(provided: any) => (
                                        <Grid
                                          item
                                          display="grid"
                                          {...provided.droppableProps}
                                          ref={provided.innerRef}
                                        >
                                          <Grid
                                            container
                                            direction="column"
                                            wrap="nowrap"
                                            gap="15px"
                                          >
                                            {lecon.sessions.map(
                                              (audio, index) => (
                                                <Draggable
                                                  key={audio._id}
                                                  draggableId={audio._id as any}
                                                  index={index}
                                                  isDragDisabled={false}
                                                >
                                                  {(provided: any) => (
                                                    <Grid
                                                      item
                                                      display="grid"
                                                      ref={provided.innerRef}
                                                      {...provided.draggableProps}
                                                      {...provided.dragHandleProps}
                                                    >
                                                      <Grid
                                                        container
                                                        direction="row"
                                                        gap="20px"
                                                      >
                                                        <Grid
                                                          item
                                                          display="grid"
                                                        >
                                                          <DisplayAudio
                                                            audio={audio}
                                                            isEditInterface={
                                                              true
                                                            }
                                                          />
                                                        </Grid>
                                                        <Grid
                                                          item
                                                          display="grid"
                                                          justifySelf={"center"}
                                                          alignSelf={"center"}
                                                        >
                                                          <Grid
                                                            container
                                                            direction="column"
                                                            wrap="nowrap"
                                                            gap="10px"
                                                            justifyContent={
                                                              "center"
                                                            }
                                                            alignItems={
                                                              "center"
                                                            }
                                                          >
                                                            <Grid
                                                              item
                                                              display="grid"
                                                            >
                                                              <ButtonIconWithTooltip
                                                                Icon={
                                                                  <EditRounded
                                                                    style={{
                                                                      color:
                                                                        Colors.Black,
                                                                      width:
                                                                        "20px",
                                                                      height:
                                                                        "20px",
                                                                    }}
                                                                  />
                                                                }
                                                                onClick={() =>
                                                                  setEditAudioOpen(
                                                                    audio
                                                                  )
                                                                }
                                                                tooltipText="Modifier"
                                                              />
                                                            </Grid>
                                                            <Grid
                                                              item
                                                              display="grid"
                                                            >
                                                              <ButtonIconWithTooltip
                                                                Icon={
                                                                  <DeleteRounded
                                                                    style={{
                                                                      color:
                                                                        Colors.Black,
                                                                      width:
                                                                        "20px",
                                                                      height:
                                                                        "20px",
                                                                    }}
                                                                  />
                                                                }
                                                                onClick={() => {
                                                                  setLecon(
                                                                    (l) => ({
                                                                      ...l,
                                                                      sessions:
                                                                        l.sessions.filter(
                                                                          (s) =>
                                                                            s._id !==
                                                                            audio._id
                                                                        ),
                                                                    })
                                                                  );
                                                                }}
                                                                tooltipText="Supprimer"
                                                              />
                                                            </Grid>
                                                          </Grid>
                                                        </Grid>
                                                      </Grid>
                                                    </Grid>
                                                  )}
                                                </Draggable>
                                              )
                                            )}
                                            {provided.placeholder}
                                          </Grid>
                                        </Grid>
                                      )}
                                    </Droppable>
                                  </DragDropContext>
                                )}
                              </Grid>
                            </Grid>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item display="grid" marginTop="15px">
                <Grid
                  container
                  direction="row"
                  gap="10px"
                  justifyContent={"center"}
                  alignItems={"center"}
                >
                  <Grid item display="grid">
                    <SecondaryButton
                      text={"Annuler"}
                      onClick={() => {
                        if (props.onClose) {
                          props.onClose();
                        }
                      }}
                    />
                  </Grid>
                  <Grid item display="grid">
                    <PrimaryButton
                      text={props.isEdit ? "Modifier" : "Créer"}
                      onClick={() => {
                        if (props.onSubmit) {
                          props.onSubmit(lecon);
                        }
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </>
      </GenericPopup>
    </>
  );
};

const Lecons: React.FC = () => {
  const role = useAppSelector((x) => x?.user?.user?.role);
  const isAdmin = role === "admin" ? true : false;
  const defaultOnglet: Onglet = "Comprendre";
  const [actualOnglet, setActualOnglet] = React.useState<Onglet>(defaultOnglet);
  const navigate = useNavigate();
  const onglet = useQuery().get("onglet");
  React.useEffect(() => {
    if (!onglet) {
      setActualOnglet(defaultOnglet);
    }
    if (onglet && onglets.includes(onglet as Onglet)) {
      setActualOnglet(onglet as Onglet);
    }
  }, [onglet]);

  const snackbarUse = useOpenSnackbar();
  const dispatch = useAppDispatch();
  const cards = useAppSelector((x) => x.lecons.lecons);
  const filteredCards = cards.filter((x) => x.onglet === actualOnglet);
  const pageLoading = useAppSelector((x) => x.lecons.isLoading);

  const [createLeconOpen, setCreateLeconOpen] = React.useState<boolean>(false);
  const [editLeconOpen, setEditLeconOpen] = React.useState<Lecon | undefined>(
    undefined
  );

  React.useEffect(() => {
    dispatch(getAllLecons())
      .unwrap()
      .then((res) => {
        return;
      })
      .catch((err) => {
        snackbarUse.error("Impossible de récupérer les leçons.");
      })
      .finally(() => {
        return;
      });
  }, [actualOnglet, dispatch]);

  return (
    <>
      <Page
        pageTitle="Leçons"
        header={
          <Grid
            container
            direction="row"
            wrap="nowrap"
            padding="12px 3px"
            alignItems={"center"}
            justifyContent={"space-evenly"}
            style={{
              backgroundColor: Colors.Black,
              borderBottom: "1px solid rgba(105, 105, 105, 0.5)",
            }}
          >
            {onglets.map((onglet) => {
              return (
                <Grid item xs display="grid">
                  <NormalText
                    fontSize="10px"
                    text={onglet}
                    color="White"
                    fontStyle="normal"
                    fontWeight={400}
                    onClick={() => {
                      setActualOnglet(onglet);
                      navigate(`/lecons?onglet=${onglet}`);
                    }}
                    style={{
                      whiteSpace: "nowrap",
                      opacity: actualOnglet === onglet ? 1 : 0.4,
                      textAlign: "center",
                      userSelect: "none",
                    }}
                  />
                </Grid>
              );
            })}
          </Grid>
        }
      >
        <>
          {editLeconOpen && (
            <CreateOrEditLecon
              isEdit={true}
              lecon={editLeconOpen}
              onClose={() => setEditLeconOpen(undefined)}
              onSubmit={(leconToEdit) => {
                if (leconToEdit._id) {
                  dispatch(
                    updateLecon({
                      data: cleanLeconBeforePut(leconToEdit),
                      id: leconToEdit._id,
                    })
                  )
                    .unwrap()
                    .then((res) => {
                      snackbarUse.success("Leçon modifiée avec succès.", res);
                      setEditLeconOpen(undefined);
                    })
                    .catch((err) => {
                      snackbarUse.error(
                        "Impossible de mettre à jour cette leçon.",
                        err
                      );
                    });
                }
              }}
            />
          )}
          {createLeconOpen && (
            <CreateOrEditLecon
              isEdit={false}
              onClose={() => setCreateLeconOpen(false)}
              onSubmit={(newLecon) => {
                dispatch(createLecon(cleanLeconBeforeSend(newLecon)))
                  .unwrap()
                  .then((res) => {
                    snackbarUse.success("Leçon créé avec succès.", res);
                    setCreateLeconOpen(false);
                  })
                  .catch((err) => {
                    snackbarUse.error("Impossible de créer cette leçon.", err);
                  });
              }}
            />
          )}
          {isAdmin && (
            <div
              style={{
                position: "absolute",
                right: 50,
                bottom: 90,
                zIndex: 9,
              }}
            >
              <div
                onClick={(e) => {
                  setCreateLeconOpen(true);
                }}
                style={{
                  background: `rgba(65, 65, 65, 1)`,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "45px",
                  height: "45px",
                  borderRadius: "50%",
                  zIndex: 1,
                  cursor: "pointer",
                  border: "1px dotted #fff",
                }}
              >
                <ButtonIconWithTooltip
                  tooltipText="Créer une leçon"
                  Icon={
                    <AddRounded
                      style={{
                        color: "#fff",
                        height: "38px",
                        width: "38px",
                      }}
                    />
                  }
                  onClick={() => {
                    return;
                  }}
                />
              </div>
            </div>
          )}
          <Grid
            container
            direction="row"
            gap="20px"
            justifyContent={"space-evenly"}
          >
            {pageLoading ? (
              <Grid item display="grid">
                <CircularProgress
                  style={{
                    color: "white",
                  }}
                />
              </Grid>
            ) : (
              <>
                {filteredCards.length === 0 ? (
                  <Grid item display="grid" paddingTop="20px">
                    <NormalText
                      text="Il n'y a aucune leçon pour le moment."
                      fontSize="16px"
                      color="White"
                    />
                  </Grid>
                ) : (
                  <>
                    {filteredCards.map((card) => {
                      return (
                        <Grid item key={card._id} display="grid">
                          <DrawCard
                            card={card}
                            onEditClick={() => {
                              setEditLeconOpen(card);
                            }}
                            onDeleteClick={() => {
                              return;
                            }}
                            onFavoriteClick={() => {
                              if (card?._id) {
                                dispatch(clickFavorite(card._id));
                              }
                            }}
                          />
                        </Grid>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </Grid>
        </>
      </Page>
    </>
  );
};

export default Lecons;
